const draftPlans = (state = [], action) => {

    switch (action.type) {
        case 'UNMOUNT_DRAFT_PLANS':
        case 'UNMOUNT_UPCOMING_DRAFT_PLANS':
            return [];
        case 'GET_DRAFT_PLANS':
            return action.draftPlans;
        case 'GET_UPCOMING_DRAFT_PLANS':
            return action.upcomingDraftPlans;
        default:
            return state;
    }
}

export default draftPlans;