import YklService from 'services/YklService';
import {extractApiErrors} from './api_errors';

const {yklAxios} = YklService;

export const loginUser = (loginData) => {
    return yklAxios.post('/users/login', loginData)
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const userAuthenticated = (decodedToken) => {
    return {
        type: 'USER_AUTHENTICATED',
        username: decodedToken.username || ''
    }
}