const draftYears = (state = [], action) => {

    switch (action.type) {
        case 'UNMOUNT_DRAFT_YEARS':
            return [];
        case 'GET_DRAFT_YEARS':
            return action.draftYears;
        default:
            return state;
    }
}

export default draftYears;