import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableHeaders = ({ orderBy, currentTab, handleSortFunc }) => {
    const sort = async keyParam => {
        let prevKey = orderBy.key;
        let newOrder = orderBy.order;
        let newKey = keyParam;

        if (newKey === prevKey) {
            if (orderBy.order.toUpperCase() === 'ASC') {
                newOrder = 'DESC';
            }
            else{
                newOrder = 'ASC';
            }
        }
        await handleSortFunc({ key: newKey, order: newOrder });
    }

    const getSortIcon = keyParam => {
        if (orderBy.key === keyParam) {
            if (orderBy.order.toUpperCase() === 'ASC') {
                return 'chevron-down';
            }
            else if (orderBy.order.toUpperCase() === 'DESC') {
                return 'chevron-up';
            }
        }
        return 'minus';
    }

    return (
        <thead id="playersHeader">
            <tr>
                {currentTab === 'watchlist' &&
                    <th id="rank" className="handCursor" onClick={() => sort('rank')}>
                        Rank
                        <FontAwesomeIcon className="ml3" id="rankIcon" icon={getSortIcon('rank')} />
                    </th>
                }
                <th id="name" className="handCursor" onClick={() => sort('name')}>
                    Name
                    <FontAwesomeIcon className="ml3" id="gamesplayedIcon" icon={getSortIcon('name')} />
                </th>
                <th id="pos" className="handCursor" onClick={() => sort('position')}>
                    Pos
                    <FontAwesomeIcon className="ml3" id="positionIcon" icon={getSortIcon('position')} />
                </th>
                <th id="draft" className="handCursor" onClick={() => sort('draftYear')}>
                    Draft
                    <FontAwesomeIcon className="ml3" id="draftyearIcon" icon={getSortIcon('draftYear')} />
                </th>
                <th id="gp" className="handCursor" onClick={() => sort('gamesPlayed')}>
                    GP
                    <FontAwesomeIcon className="ml3" id="gamesplayedIcon" icon={getSortIcon('gamesPlayed')} />
                </th>
                <th id="points" className="handCursor" onClick={() => sort('points')}>
                    PTS
                    <FontAwesomeIcon className="ml3" id="pointsIcon" icon={getSortIcon('points')} />
                </th>
                <th id="projPoints" className="handCursor" onClick={() => sort('projPoints')}>
                    Proj P
                    <FontAwesomeIcon className="ml3" id="projPointsIcon" icon={getSortIcon('projPoints')} />
                </th>
                <th id="pointspergame" className="handCursor" onClick={() => sort('pointsPerGame')}>
                    PTS/G
                    <FontAwesomeIcon className="ml3" id="pointspergameIcon" icon={getSortIcon('pointsPerGame')} />
                </th>
                <th id="wins" className="handCursor" onClick={() => sort('wins')}>
                    Wins
                    <FontAwesomeIcon className="ml3" id="winsIcon" icon={getSortIcon('wins')} />
                </th>
                <th id="shutouts" className="handCursor" onClick={() => sort('shutouts')}>
                    SO
                    <FontAwesomeIcon className="ml3" id="shutoutsIcon" icon={getSortIcon('shutouts')} />
                </th>
                <th id="injury">
                    Injury
                </th>
                {currentTab === 'watchlist' &&
                    <th id="trash"></th>
                }
            </tr>
        </thead>
    );
}

export default TableHeaders;