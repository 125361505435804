/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import PlayersTable from 'components/players/players_table_data';
import PlayersTabs from 'components/players/players_tabs';

import TableHeaders from 'components/shared/table_headers';
import Search from 'components/shared/search';
import { getPlayerStats } from 'components/shared/functions';

import { getUserWatchlist, addToWatchlist, removeFromWatchlist, updateRankings } from 'api/api_watchlist';
import {isEmpty} from 'services/global';

const Players = () => {
    const [currentTab, setCurrentTab] = useState('players');
    const [filters, setFilters] = useState({});
    const [orderBy, setOrderBy] = useState({ key: 'points', order: 'DESC' });
    const [players, setPlayers] = useState([]);
    const [watchlist, setWatchlist] = useState([]);
    const [watchlistMax, setWatchlistMax] = useState(0);

    const handleGetWatchlist = () => {
        getUserWatchlist().then(watchlist => {
            setWatchlist(watchlist);
            setWatchlistMax(watchlist.length);
        });
    }

    const handleOriginalCalls = () => {
        handleGetWatchlist();

        getPlayerStats().then(players => {
            setPlayers(players);
        });
    };

    useEffect(() => {
        document.title = "YKL Player Stats";
        handleOriginalCalls();

        return () => {
            setCurrentTab('players');
            setFilters({});
            setOrderBy({ key: 'points', order: 'DESC' });
            setPlayers([]);
            setWatchlist([]);
            setWatchlistMax(0);
        }
    }, []);

    const handleSetTab = currentTab => {
        setCurrentTab(currentTab);

        if(currentTab === 'watchlist'){
            setOrderBy({ key: 'rank', order: 'ASC'});
        }
        else if(currentTab === 'players' && orderBy.key === 'rank'){
            setOrderBy({ key: 'points', order: 'DESC'});
        }
    }

    const handleSearch = filters => {
        setFilters(filters);
    }

    const handleSortFunc = async orderBy => {
        setOrderBy(orderBy);
    }

    const handleWatchlistActions = (action, playerID, newRankOrName = null) => {
        switch (action) {
            case 'add':
                addToWatchlist(playerID).then(res => {
                    if (!isEmpty(res.rank)) {
                        toast.success('Successfully added ' + newRankOrName + ' to your watchlist', { autoClose: 3500 });
                        handleGetWatchlist();
                    }
                    else {
                        toast.error('Failed to add ' + newRankOrName + ' to your watchlist', { autoClose: 3500 });
                    }
                }, () => {
                    toast.error('Failed to add ' + newRankOrName + ' to your watchlist', { autoClose: 3500 });
                });
                break;
            case 'remove':
                removeFromWatchlist(playerID).then(() => {
                    handleGetWatchlist();
                });
                break;
            case 'update':
                updateRankings({ playerID: playerID, newRank: newRankOrName }).then(() => {
                    handleGetWatchlist();
                });
                break;
            default:
                break;
        }
    }

    return (
        <>
            <PlayersTabs handleSetTab={handleSetTab} />
            <Search handleSearch={handleSearch} />
            <div id="playersTableDiv">
                <table id="playersTable" className="table table-responsive table-bordered table-striped">
                    <TableHeaders orderBy={orderBy} currentTab={currentTab} handleSortFunc={handleSortFunc} />
                    <PlayersTable players={players} watchlist={watchlist} currentTab={currentTab} filters={filters} 
                    orderBy={orderBy} handleWatchlistActions={handleWatchlistActions} watchlistMax={watchlistMax} />
                </table>
            </div>
        </>
    );
}

export default Players;