import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";

import AuthRoute from 'components/auth/AuthRoute';
import AuthDraftRoute from 'components/auth/AuthDraftRoute';
import GuestRoute from 'components/auth/GuestRoute';

import Home from './pages/home';
import Login from './pages/login';
import Players from './pages/players';
import Draft from './pages/draft';
import DraftPlans from './pages/draftplans';

const Routes = () => {
    return (
        <div className="jumbotron">
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <AuthRoute path="/players">
                    <Players />
                </AuthRoute>
                <AuthDraftRoute path="/draft">
                    <Draft />
                </AuthDraftRoute>
                <AuthRoute path="/draftplans">
                    <DraftPlans />
                </AuthRoute>
                <GuestRoute path="/login">
                    <Login />
                </GuestRoute>
                {/* Re-route any invalid paths to home */}
                <Route render={() => 
                    <Redirect to={{pathname: "/"}} />} 
                />
            </Switch>
        </div>
    )
}

export default Routes;