import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { countdownToPreDraft } from 'services/timers';
import { useAuth } from 'providers/AuthProvider';

const Header = ({ isAuth, logout }) => {
    const authService = useAuth();
    const token = authService.getToken();
    const [preDraftTimer, setPreDraftTimer] = useState('empty');
    const navbarRef = useRef(null);
    const renderCount = useRef(0); // Initialize render count

    useEffect(() => {
        const countdownService = countdownToPreDraft();
        const subscription = countdownService.subscribe((preDraftTimer) => {
            setPreDraftTimer(preDraftTimer);
        });

        return () => {
            subscription.unsubscribe();
        };

    }, [preDraftTimer]);

    useEffect(() => {
        // Increment the render count if the user is logged in
        if(token !== undefined && token !== null && token !== '') {
            renderCount.current += 1;
        }
        else { //If the user isn't logged in,r eset that count
            renderCount.current = 0;
        }
        //if the user's logged in, and it's the 2nd render count (2nd render has all the nav links loaded up; first doesnt),
        //then add the event listeners to collapse the nav after every click.
        if(token !== undefined && token !== null && token !== '' && renderCount.current === 2) {
            const navLinks = document.querySelectorAll('.navbar-collapse a');
            const navBar = document.querySelector('.navbar-collapse');
            const navbar_brand = document.querySelector('.navbar-brand');

            navLinks.forEach(link => {
                link.removeEventListener('click', () => {});
                link.addEventListener('click', () => {
                    // Collapse the navbar when a link is clicked
                    navBar.classList.remove('show');
                });
            });

            navbar_brand.removeEventListener('click', () => {});
            navbar_brand.addEventListener('click', () => {
                // Collapse the navbar when the navbar brand is clicked
                navBar.classList.remove('show');
            });
        }
    // eslint-disable-next-line
    }, [token, renderCount.current]);

    const openDraftWindow = () => {
        return window.open(`/draft?token=${token}`, 'YKL_LIVE_DRAFT', `menubar=0,toolbar=0,location=0,status=1,resizable=1,scrollbars=1,noopener=0,height=${window.outerHeight},width=${window.outerWidth}`);
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="/favicon.ico?" style={{height: 40}} alt="Youngster Keeper League Logo"></img>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarResponsive" ref={navbarRef}>
                        <ul className="navbar-nav ml-auto">
                            {isAuth ?
                                <>
                                    {preDraftTimer !== 'now' &&
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/draftplans">
                                                Draft Plans <span className="sr-only">(current)</span>
                                            </Link>
                                        </li>
                                    }
                                    {preDraftTimer !== 'now' &&
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/players">
                                                Players <span className="sr-only">(current)</span>
                                            </Link>
                                        </li>
                                    }
                                    {preDraftTimer === 'now' &&
                                        <li className="nav-item">
                                            <span className="handCursor" onClick={() => openDraftWindow()}>
                                                LIVE DRAFT! <span className="sr-only">(current)</span>
                                            </span>
                                        </li>
                                    }
                                    {preDraftTimer !== 'now' &&
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/logout" onClick={logout}>Logout</Link>
                                        </li>
                                    }
                                </>
                                :
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">
                                        Login <span className="sr-only">(current)</span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

const mapStateToProps = ({ auth: { isAuth } }) => {
    return {
        isAuth
    }
}

export default connect(mapStateToProps)(Header);