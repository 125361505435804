import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import auth from './reducers/auth_reducer';
import draftPlans from './reducers/draftplans_reducer';
import players from './reducers/players_reducer';
import draftYears from './reducers/reducer_draft_years';

export function initStore() {
    
    const reducers = combineReducers({
        auth,
        draftPlans,
        players,
        draftYears
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

    return store;
}