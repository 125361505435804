import React from 'react';
import {loginUser, userAuthenticated} from 'api';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import {connect} from 'react-redux';
import Cookies from 'universal-cookie';
import { isEmpty } from 'services/global';

const {createContext, useContext} = React;
const AuthContext = createContext(null);
const cookies = new Cookies();

const AuthBaseProvider = ({children, dispatch}) => {

    const checkAuthState = () => {
        
        const decodedToken = decodeToken(getToken());
        if(decodedToken && moment().isBefore(getExpiration(decodedToken))) {
            dispatch(userAuthenticated(decodedToken));
        }
    }

    const isAuthenticated = () => {
        const decodedToken = decodeToken(getToken());
        return decodedToken && isTokenValid(decodedToken);
    }

    const isTokenValid = (decodedToken) => {
        return decodedToken && moment().isBefore(getExpiration(decodedToken));
    }

    const getExpiration = decodedToken => {
        if(!isEmpty(decodedToken)) {
            return moment.unix(decodedToken.exp);
        }
        return null;
    }

    const getToken = () => {
        return cookies.get('ykl_token');
    }

    const decodeToken = token => {
        return jwt.decode(token);
    }

    const getUsername = () => {
        const decodedToken = decodeToken(getToken());
        if(isEmpty(decodedToken)) {
            return null;
        }
        return decodedToken.username;
    }

    const signOut = () => {
        cookies.remove('ykl_token', { path: '/', expires: new Date() - 3600 });
        // localStorage.removeItem('ykl_token');
        dispatch({type: 'USER_SIGNED_OUT'});
    }

    const signIn = (loginData) => {
        return loginUser(loginData).then(token => {

            cookies.set('ykl_token', token, { path: '/', secure: true});

            // localStorage.setItem('ykl_token', token);
            const decodedToken = decodeToken(token);

            dispatch(userAuthenticated(decodedToken));
            return token;
        });
    }

    //TODO: FIX THIS
    const isDraftTime = () => {
        // if(preDraftTimer !== 'now') {
        //     return false;
        // }
        return true;
    }

    const authApi = {
        signIn,
        checkAuthState,
        signOut,
        isAuthenticated,
        getToken,
        getUsername,
        isDraftTime
    }

    return (
        <AuthContext.Provider value={authApi}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthProvider = connect()(AuthBaseProvider);

export const useAuth = () => {
    return useContext(AuthContext);
}

export const withAuth = Component => props => (
    <AuthContext.Consumer>
        {authApi => <Component {...props} auth={authApi} />}
    </AuthContext.Consumer>
)