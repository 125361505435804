/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'services/global';
import { countdownToPreDraft, countdownToActualDraft } from 'services/timers';
import { useAuth } from 'providers/AuthProvider';
import { Link } from "react-router-dom";

const Home = () => {

    const authService = useAuth();
    const username = authService.getUsername();
    const token = authService.getToken();
    const [preDraftTimer, setPreDraftTimer] = useState('empty');
    const [actualDraftTimer, setActualDraftTimer] = useState('empty');

    useEffect(() => {
        document.title = "Youngster Keeper League";
    }, []);

    useEffect(() => {
        const countdownService = countdownToPreDraft();
        const countdownService2 = countdownToActualDraft();

        const subscription = countdownService.subscribe((preDraftTimer) => {
            setPreDraftTimer(preDraftTimer);
        });
        const subscription2 = countdownService2.subscribe((actualDraftTimer) => {
            setActualDraftTimer(actualDraftTimer);
        });

        return () => {
            subscription.unsubscribe();
            subscription2.unsubscribe();
        };
    }, [actualDraftTimer]);

    const openDraftWindow = () => {
        //REMINDER: make sure to change header.js if you change anything here
        return window.open(`/draft?token=${token}`, 'YKL_LIVE_DRAFT', `menubar=0,toolbar=0,location=0,status=1,resizable=1,scrollbars=1,noopener=0,height=${window.outerHeight},width=${window.outerWidth}`);
    }

    return (
        <div>
            {(isEmpty(preDraftTimer) || preDraftTimer === 'empty') &&
                <h2 className="alert alert-main tac">Loading time until Draft Day...</h2>
            }
            {(!isEmpty(preDraftTimer) && preDraftTimer !== 'now' && preDraftTimer !== 'empty') &&
                <h2 className="alert alert-main tac">Draft Portal Opens in: {preDraftTimer}</h2>
            }
            {(preDraftTimer === 'now' && actualDraftTimer !== 'now' && actualDraftTimer !== 'completed' && !isEmpty(username)) &&
                <>
                    <h3 className="alert alert-main tac">
                        The draft portal is NOW OPEN! Time remaining until the live draft: <b>{actualDraftTimer}. </b>
                        <a onClick={() => openDraftWindow()} href="#">Click here</a> to go to the draft page.
                    </h3>
                </>
            }
            {(preDraftTimer === 'now' && actualDraftTimer !== 'now' && actualDraftTimer !== 'completed' && isEmpty(username)) &&
                <>
                    <h3 className="alert alert-main tac">
                        The draft portal is NOW OPEN! Time remaining until the live draft: <b>{actualDraftTimer}. </b>
                        Please <Link to="/login">login</Link> first to join in on the fun!
                    </h3>
                </>
            }
            {(actualDraftTimer === 'now' && !isEmpty(username)) &&
                <h3 className="alert alert-danger tac">
                    YOUR DRAFT IS CURRENTLY UNDER WAY! <a onClick={() => openDraftWindow()} href="#">Click here</a> to get into the draft!
                </h3>
            }
            {(actualDraftTimer === 'now' && isEmpty(username)) &&
                <h3 className="alert alert-danger tac">
                    YOUR DRAFT IS CURRENTLY UNDER WAY! Hurry, please <Link to="/login">login</Link> to be able to draft!
                </h3>
            }

            <h2>Information</h2>
            <ul>
                <li><b>$60 entry</b>. Winner gets $360, 2nd place gets $120.</li>
                <li>Drafts for skaters: 2015-2024, including undrafted players who were born in {new Date().getFullYear() - 27} or later.</li>
                <li>Goalies can be from any draft (Ex: Your 2 goalies could be Oettinger and Bobrovsky).</li>
                <li>Each team will have a roster of 15 players: <b>8FW, 2D, I UTIL, 1G, 3B</b>, with 3 IR+ positions when needed.</li>
                <li>Max moves per week: <b>5</b></li>
                <li>Goalie points are the following: <b>2P for a win, and an additional point for a shutout.</b> This points system
                    is reflected in total points for goalies on the Players/Live Draft page.</li>
                <li>Teams that didn't make the playoffs, or eliminated teams will NOT be able to make any moves while playoffs are ongoing.</li>
                <li>Playoffs will be re-seeded after every round.</li>
            </ul>
        </div>
    );
}

export default Home;