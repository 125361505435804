/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'services/global';
import {Alert} from 'react-bootstrap';

const DraftTabs = ({ socket, username, handleSetTab }) => {
    const [currentTab, setCurrentTab] = useState('players');
    const [upcomingDraftPlans, setUpcomingDraftPlans] = useState('');
    const [showInfo, setShowInfo] = useState(true);

    //only want to run this once, so have empty array
    useEffect(() => {
        socket.emit("getUpcomingDraftPlans", username);

        socket.on("setUpcomingDraftPlans", draftPlans => {
            //this will be a string returned from the server, with the users upcoming 2 pick plans
            setUpcomingDraftPlans(draftPlans);
        });
    }, []);

    const setTab = (newTab) => {
        if (currentTab !== newTab) {
            setCurrentTab(newTab);
            handleSetTab(newTab);
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                {!isEmpty(upcomingDraftPlans) &&
                    <h4 className="draftH4 mb10">Upcoming Draft Plans: {upcomingDraftPlans}</h4>
                }
            </div>
            {showInfo && (sessionStorage.getItem('closed_info') == null || sessionStorage.getItem('closed_info') === 0) &&
                <div className="col-12">
                    <Alert variant="main-light" onClose={() => { setShowInfo(false); sessionStorage.setItem('closed_info', 1);}} dismissible className="mb10">
                        <FontAwesomeIcon className="fa-lg" icon="info-circle" style={{ color: '', marginRight: 5 }} />
                        Click on a player's row (not the w icon) to select the player. If on mobile, injuries are in the table in the right-most column.
                    </Alert>
                </div>
            }

            <div className="col-12 col-lg-6 mb10">
                <button type="button" className={`handCursor btn btn-block ${currentTab === 'players' ? 'btn-main' : 'btn-outline-main'}`}
                    onClick={e => setTab(e.target.value)} value="players">
                    Players
                </button>
            </div>
            <div className="col-12 col-lg-6 mb10">
                <button type="button" className={`handCursor btn btn-block ${currentTab !== 'players' ? 'btn-main' : 'btn-outline-main'}`}
                    onClick={e => setTab(e.target.value)} value="watchlist">
                    My Watchlist
                </button>
            </div>
        </div>
    )
}

export default DraftTabs;