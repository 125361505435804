/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeebly } from '@fortawesome/free-brands-svg-icons';
import { getPlayerStats } from 'components/shared/functions';
import { isEmpty } from 'services/global';
import { selectPlayer } from 'api/api_draft';
import { countdownToActualDraft } from 'services/timers';
const _ = require('lodash');

const DraftTable = ({ socket, currentTab, handleShowConfirm, username, filters, orderBy, userRoster, playerObjDrafted, handleSetPlayerObjDrafted }) => {

    const [watchlist, setWatchlist] = useState([]);
    const [players, setPlayers] = useState([]);
    const [draftPicks, setDraftPicks] = useState([]);
    const [currentPickBeforeSelecting, setCurrentPickBeforeSelecting] = useState(null);
    const [currentPick, setCurrentPick] = useState(null);
    const [currentDrafter, setCurrentDrafter] = useState(null);
    const [playersCloneArr, setPlayersCloneArr] = useState(_.cloneDeep(players));
    const [draftDate, setDraftDate] = useState('empty');

    //useEffect for the countdown to the actual draft, then stops once it's reached draft time!
    //It's for checking whether or not the user can select a player yet or not.
    useEffect(() => {
        const countdownService = countdownToActualDraft();
        const subscription = countdownService.subscribe((draftDate) => {
            setDraftDate(draftDate);
        });

        return () => {
            subscription.unsubscribe();
        };

    }, [draftDate]);

    useEffect(() => {
        getPlayerStats().then(players => {
            setPlayers(players);
            setPlayersCloneArr(_.cloneDeep(players));
        });

        socket.emit("getCurrentPickAndDrafter");
        socket.emit("getDraftPicks");
        socket.emit("getUserWatchlist");
        socket.on("setUserWatchlist", watchlist => {
            setWatchlist(watchlist);
        });
    }, []);

    useEffect(() => {
        socket.emit("getUserRoster", userRoster);
    }, [currentPick]);

    useEffect(() => {
        socket.on("setCurrentPickAndDrafter", (currentPick, currentDrafter) => {
            setCurrentPick(currentPick);
            setCurrentPickBeforeSelecting(currentPick);
            setCurrentDrafter(currentDrafter);
        });
        socket.on("setDraftPicks", draftPicks => {
            setDraftPicks(draftPicks);
        });
    }, [currentPick, draftPicks]);

    useEffect(() => {
        if(isEmpty(playerObjDrafted) || currentPickBeforeSelecting !== currentPick || currentDrafter.toLowerCase() !== username.toLowerCase()) {
            failedPickCalls();
            return;
            // return alert("Oh no, you took to long to pick a player. We had to auto-select a player for you.");
        }
        socket.emit('setIsSelectingPlayer', true);

        selectPlayer(playerObjDrafted._id).then(function () {
            const playerObjSent = {
                fkSkater: playerObjDrafted._id,
                timestamp: Date.now(),
                name: playerObjDrafted.name,
                position: playerObjDrafted.position,
                injury: playerObjDrafted.injury,
            }
            handleSetPlayerObjDrafted();
            socket.emit('selectPlayer', playerObjSent, currentPickBeforeSelecting);
            socket.emit("getUpcomingDraftPlans", username);
        }, error => {
            failedPickCalls();
            return alert(error[0].detail);
        });
    }, [playerObjDrafted]);

    const failedPickCalls = () => {
        socket.emit('setIsSelectingPlayer', false);
        handleSetPlayerObjDrafted();
        socket.emit("getUpcomingDraftPlans", username);
    }
    const addToWatchlist = (playerID) => {
        socket.emit("addToWatchlist", username, playerID);
    }

    const removeFromWatchlist = (playerID) => {
        socket.emit("removeFromWatchlist", username, playerID);
    }

    const selectPlayerFunc = (player, e) => {
        if (currentDrafter !== username || e.target.classList.contains('dontdoit') || draftDate !== 'now' ||
            e.target.parentElement.classList.contains('dontdoit')) {
            return false;
        }
        else {
            setCurrentPickBeforeSelecting(currentPick);
            handleShowConfirm(player);
        }
    }

    const renderIcons = (injury) => {
        if (!isEmpty(injury)) {
            return (
                <FontAwesomeIcon className="fa-lg handCursor dontdoit" icon="info-circle" title={injury} style={{ color: 'red', marginLeft: 3 }} />
            );
        }
        else {
            return null;
        }
    }

    const renderPlayers = () => {
        let draftYear = '';
        let position = [''];
        let team = '';
        let search = '';

        if (filters !== undefined && filters.draftYear !== undefined && filters.draftYear.value !== 'All') {
            draftYear = filters.draftYear.value;
        }

        if (filters !== undefined && filters.position !== undefined && filters.position.value === 'Skaters') {
            position = ["FWD", "DEF"];
        }
        else if (filters !== undefined && filters.position !== undefined && filters.position.value !== 'All') {
            position = [filters.position.value];
        }

        if (filters !== undefined && filters.team !== undefined && filters.team.value !== 'All') {
            team = filters.team.value;
        }
        if (filters !== undefined && filters.search !== undefined) {
            search = filters.search;
        }
        var sortFunc = (a, b) => (orderBy.order === 'ASC' ? (a[orderBy.key] - b[orderBy.key]) : (b[orderBy.key] - a[orderBy.key]));

        if (orderBy.key === 'draftYear' || orderBy.key === 'name' || orderBy.key === 'position') {
            sortFunc = (a, b) => (orderBy.order === 'ASC' ? (b[orderBy.key].localeCompare(a[orderBy.key]))
                : a[orderBy.key].localeCompare(b[orderBy.key]));
        }

        return playersCloneArr
            .filter(player => player.draftYear.includes(draftYear))
            .filter(player => position.some(pos => player.position.includes(pos)))
            .filter(player => player.team.includes(team))
            .filter(player => player.name.toLowerCase().includes(search.toLowerCase()))
            .filter(player => !draftPicks.some(pick => (!isEmpty(pick) && player._id === pick.fkSkater)))
            .filter(currentTab === 'watchlist' ? (player => watchlist.some(p => player._id === p.fkSkater)) : (() => true))
            .sort(sortFunc)
            .map(player => {
                //reset all player ranks in case one was removed from watchlist
                player.rank = null;

                if (!isEmpty(watchlist) && watchlist.length > 0) {
                    watchlist.forEach(p => {
                        if (p.fkSkater === player._id) {
                            player.rank = p.rank;
                        }
                    });
                }

                return (
                    <tr key={player._id} onClick={e => selectPlayerFunc(player, e)}

                        className={`${!isEmpty(username) && !isEmpty(currentDrafter) && draftDate === 'now'
                            && username === currentDrafter ? 'handCursor playerRow' : ''}`}>
                        {currentTab === 'watchlist' &&
                            <td>{player.rank}</td>
                        }
                        <td>
                            {currentTab === 'players' && (isEmpty(player.rank)) &&
                                <FontAwesomeIcon className="handCursor mr5 fa-lg dontdoit" icon={faWeebly} style={{ color: 'green' }}
                                    onClick={e => addToWatchlist(player._id, e)}
                                    title={`Add ${player.name} to my watchlist`} />
                            }
                            {player.name} ({player.team})
                            {renderIcons(player.injury)}
                        </td>
                        <td>{player.position}</td>
                        <td>{player.draftYear}</td>
                        <td>{player.gamesPlayed}</td>
                        <td>{player.points}</td>
                        <td>{player.projPoints}</td>
                        <td>{player.pointsPerGame}</td>
                        <td>{player.wins === null ? 'N/A' : player.wins}</td>
                        <td>{player.shutouts === null ? 'N/A' : player.shutouts}</td>
                        <td className="injury-td">{player.injury === null ? '' : player.injury}</td>
                        {currentTab === 'watchlist' &&
                            <td className="handCursor dontdoit" title={`Remove ${player.name} from your watchlist`}
                                onClick={e => removeFromWatchlist(player._id, e)}>
                                <FontAwesomeIcon className="dontdoit" icon="trash" style={{ color: 'red' }} />
                            </td>
                        }
                    </tr>
                )
            }
        );
    }

    return (
        <tbody>
            {renderPlayers()}
        </tbody>
    );
}

export default DraftTable;