/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect, useRef} from 'react';

const DraftChat = ({socket, username}) => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const chatDivRef = useRef(null);

    useEffect(() => {
        socket.emit("getComments");
    }, []);

    useEffect(() => {
        socket.on('setComments', async data => {
            setComments(data);
            if(chatDivRef.current){
                chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
            }
        });
    }, []);

    const postComment = async (e) => {
        //don't want to refresh the page, so prevent that
        e.preventDefault();
        await socket.emit('postComment', username, comment);
        setComment('');
    };

    const renderComments = () => {
        if (comments && comments.length > 0) {
            return comments.map(c => {
                let timestamp = new Date(c.timestamp);
                let hour = timestamp.getHours();
                let mins = timestamp.getMinutes();
                let secs = timestamp.getSeconds();

                if (mins < 10) {
                    mins = ("0" + mins);
                }
                if (secs < 10) {
                    secs = ("0" + secs);
                }

                return (
                    <div className="col-12 chatRow" key={c._id}>
                        <div className="chatUsername" title={`${c.fkUsername}`}>
                            {c.fkUsername}
                        </div>
                        <div className="chatPost">
                            {c.message}
                        </div>
                        <div className="chatTimestamp">
                            {
                                hour + ":" + mins + ":" + secs
                            }
                        </div>
                    </div>
                );
            });
        }
    }
    return (
        <div className="row">
            <div className="col-12">
                <h4 className="tac" style={{ fontSize: 16 }}>Chat</h4>
                <form onSubmit={e => postComment(e)}>
                    <input id="comment" type="text" className="form-control mb10" name="comment"
                        placeholder='Hit "Enter" to comment...' maxLength="255" onChange={e => setComment(e.target.value)}
                        style={{ fontSize: 12 }} value={comment}>
                    </input>
                </form>
                <div id="chatDiv" className="mb20" ref={chatDivRef}>
                    {renderComments()}
                </div>
            </div>
        </div>
    );
}

export default DraftChat;