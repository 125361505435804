import YklService from 'services/YklService';
import {extractApiErrors} from './api_errors';

const {yklAxios} = YklService;

export const getOnlineUsers = () => {
    return yklAxios.get('/draft/onlineusers')
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const getComments = () => {
    return yklAxios.get('/draft/comments')
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const getPicks = () => {
    return yklAxios.get('/draft/picks')
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const getUserRoster = username => {
    return yklAxios.post('/draft/roster', {username})
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const selectPlayer = skaterID => {
    return yklAxios.post('/draft/select', {skaterID})
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const postComment = message => {
    return yklAxios.post('/draft/comment', {message})
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}