import YklService from 'services/YklService';
import {extractApiErrors} from './api_errors';

const {yklAxios} = YklService;

export const getUserWatchlist = () => {
    return yklAxios.get('/watchlist/user')
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const addToWatchlist = (playerID) => {
    return yklAxios.post('/watchlist/add', {playerID: playerID})
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const removeFromWatchlist = (playerID) => {
    return yklAxios.post('/watchlist/remove', {playerID: playerID})
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const updateRankings = (data) => {
    //data should contain playerID and newRank
    return yklAxios.post('/watchlist/update', data)
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}