import YklService from 'services/YklService';
import {extractApiErrors} from './api_errors';

const {yklAxios} = YklService;

export const getDraftPlans = () => dispatch => {
    return yklAxios.get('/draftplans').then(res => {
        return dispatch({
            type: 'GET_DRAFT_PLANS',
            draftPlans: res.data.plans,
            keepers: res.data.keepers
        });
    });
}

export const getUpcomingDraftPlans = () => {
    return yklAxios.get('/draftplans/upcoming')
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}

export const updateDraftPlans = plans => {
    return yklAxios.post('/draftplans/update', plans)
    .then(res => res.data)
    .catch(error => Promise.reject(extractApiErrors(error.response || {})));
}