import { Subject } from "rxjs";
import {isEmpty} from 'services/global';
import * as workerTimers from 'worker-timers';

var currDrafterLastSeen = '';

export function countdownToPreDraft() {
    const subject = new Subject();

    const countdownPreDraftDate = new Date("October 6, 2024 20:00:00").getTime();
    
    const interval = setInterval(() => {
        var now = new Date().getTime();
        var distance = countdownPreDraftDate - now;
        var preDraftDate;

        if(distance < 0) {
            preDraftDate = 'now';
            subject.next(preDraftDate);
            clearInterval(interval);
            subject.complete();
            return subject;
        }

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        preDraftDate = days + "d " + hours + "h " + minutes + "m " + seconds + "s";

        if(days <= 0 && hours > 0) {
            preDraftDate = hours + "h " + minutes + "m " + seconds + "s";
        }
        else if(days <= 0 && hours <= 0) {
            if(minutes < 10) {
                minutes = ("0" + minutes);
            }
            if(seconds < 10) {
                seconds = ("0" + seconds);
            }
            preDraftDate = minutes + ":" + seconds;
        }

        //if draft day has finally come, t minus 20 mins, clear the interval and set the date to null
        if ((days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) || subject.isStopped) {
            preDraftDate = 'now';
            //need to send subject.next() here or else preDraftDate would be stuck at 1 second instead of 'now'
            subject.next(preDraftDate);
            clearInterval(interval);
            subject.complete();
        }
        subject.next(preDraftDate);
    }, 100);

    return subject;
}

export function countdownToActualDraft() {
    const subject = new Subject();

    const countDownDate = new Date("October 6, 2024 20:30:00").getTime();
    
    const interval = setInterval(() => {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var actualDraftDate;

        //3 hours from the draft start time.
        if(distance < -10800000) {
            actualDraftDate = 'completed';
            subject.next(actualDraftDate);
            clearInterval(interval);
            subject.complete();
            return subject;
        }
        else if(distance < 0) {
            actualDraftDate = 'now';
            subject.next(actualDraftDate);
            clearInterval(interval);
            subject.complete();
            return subject;
        }

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        actualDraftDate = days + "d " + hours + "h " + minutes + "m " + seconds + "s";

        if(days <= 0 && hours > 0) {
            actualDraftDate = hours + "h " + minutes + "m " + seconds + "s";
        }
        else if(days <= 0 && hours <= 0) {
            if(minutes < 10) {
                minutes = ("0" + minutes);
            }
            if(seconds < 10) {
                seconds = ("0" + seconds);
            }
            actualDraftDate = minutes + ":" + seconds;
        }

        //if draft day has finally come, t minus 20 mins, clear the interval and set the date to null
        if ((days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) || subject.isStopped) {
            actualDraftDate = 'now';
            //need to send subject.next() here or else actualDraftDate would be stuck at 1 second instead of 'now'
            subject.next(actualDraftDate);
            clearInterval(interval);
            subject.complete();
        }
        subject.next(actualDraftDate);
    }, 100);

    return subject;
}

export function draftTimer(draftTimerNewTime, currentDrafter, onlineUsers) {
    const subject = new Subject();
    var autoDraftPlayer = false;

    if(isEmpty(draftTimerNewTime) || draftTimerNewTime === 'completed') {
        return subject;
    }

    //3 mins for a pick, so to start off, make it 3 mins after the start of the draft
    const countDownDate = new Date(draftTimerNewTime).getTime();

    const interval = workerTimers.setInterval(() => {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var timeLeft;

        if(distance < 0) {
            timeLeft = 'expired';
            //need to send subject.next() here or else time would be stuck at 1 second
            subject.next(timeLeft);
            workerTimers.clearInterval(interval);

            //make the auto pick and reset the interval to 3 mins
            subject.complete();
            return subject;
        }
        if(!onlineUsers.toLowerCase().includes(currentDrafter.toLowerCase())){
            // if(shouldAutoDraft('', draftTimerNewTime)){
            //     autoDraftPlayer = true;
            // }
        }

        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if(seconds >= 0 && seconds < 10) {
            seconds = "0" + seconds;
        }
        timeLeft = minutes + ":" + seconds;

        if(autoDraftPlayer === true) {
            timeLeft = 'autodraft';
            //need to send subject.next() here or else time would be stuck at 1 second
            subject.next(timeLeft);
            workerTimers.clearInterval(interval);

            //make the auto pick and reset the interval to 3 mins
            subject.complete();
        }
        else if ((minutes <= 0 && seconds <= 0) || subject.isStopped) {
            timeLeft = 'expired';
            //need to send subject.next() here or else time would be stuck at 1 second
            subject.next(timeLeft);
            workerTimers.clearInterval(interval);

            //make the auto pick and reset the interval to 3 mins
            subject.complete();
        }
        subject.next(timeLeft);
    }, 100);

    return subject;
}

export function setCurrDrafterLastSeenForTimer(currDrafterLastSeenTmp) {
    currDrafterLastSeen = currDrafterLastSeenTmp;
}

export function shouldAutoDraft(currDrafterLastSeenTmp, draftTimerNewTime) {
    if(!isEmpty(currDrafterLastSeenTmp)){
        currDrafterLastSeen = currDrafterLastSeenTmp;
    }
    const currTime = Math.floor(Date.now() / 1000);
    const countDownDate = new Date(draftTimerNewTime).getTime();
    let autoDraftPlayer = false;
    //If the user was never last seen, give them 10 seconds to get on
    //do +170 since it's a 3 min offset. 60 * 3 = 180. 180-10 seconds = 170.
    //countdowndate: 1,632,975,900 --- 10:25pm
    //now @ 1st increment +170 10: 1,632,975,890
    //now after 10 seconds +170 : 1,632,975,900
    //If the user was seen at one point, give them 1 minute to get back
    //example:
    //last seen: 1,632,975,300
    //last seen + 60: 1,632,975,360
    //curr time: 1,632,975,330
    //They were on 30 seconds ago, so wait another 30 seconds

    if( (currDrafterLastSeen === 'never' && (currTime + 170) >= Math.floor(countDownDate/ 1000) ) || currTime >= (currDrafterLastSeen + 60)){
        // autoDraftPlayer = true;
    }
    return autoDraftPlayer;
}
