import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class YklService {
    axiosInstance = null;

    constructor(){
        this.initInstance();
    }

    initInstance(){
        this.axiosInstance = axios.create({
            baseURL: '/api',
            timeout: 5000
        });

        this.axiosInstance.interceptors.request.use((config) => {
            const token = cookies.get('ykl_token');
            // const token = localStorage.getItem('ykl_token');

            if(token){
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });
    }
    
    get yklAxios() {
        return this.axiosInstance;
    }
}
// eslint-disable-next-line
export default new YklService();