import React, { useEffect } from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Header from './header';
import Routes from './routes';
import {AuthProvider, useAuth} from 'providers/AuthProvider';
import {initStore} from "./store";

const store = initStore();

const Providers = ({children}) =>
    <Provider store={store}>
        <AuthProvider>
            {children}
        </AuthProvider>
    </Provider>

const YklApp = () => {
    const authService = useAuth();

    useEffect(() => {
        authService.checkAuthState();
    }, [authService]);

    return (
        <Router>
            <Header logout={authService.signOut} />
            <ToastContainer 
                autoClose={5000}
                newestOnTop={true}
                pauseOnFocusLoss={false}
                draggable={false}
                limit={5}
            />
            <Routes />
        </Router>
    )
}

const App = () => {
    return (
        <Providers>
            <YklApp />
        </Providers>
    )
}

export default App;
