import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from 'providers/AuthProvider';

const AuthDraftRoute = ({children, ...rest}) => {
    const authService = useAuth();
    const onlyChild = React.Children.only(children);
    
    return (
        <Route {...rest} render={(props) => authService.isAuthenticated() && authService.isDraftTime() ? 
        React.cloneElement(onlyChild, {...rest, ...props}) :
        <Redirect to={{pathname: '/home'}} /> } />
    )
}

export default AuthDraftRoute;