/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'services/global';
import { getAllUsers } from 'api/api_users';
const _ = require('lodash');

const DraftRightPane = ({ socket, username, handleUserRoster }) => {

    const [forwards, setForwards] = useState([]);
    const [defense, setDefense] = useState([]);
    const [goalies, setGoalies] = useState([]);
    const [utils, setUtils] = useState([]);
    const [bench, setBench] = useState([]);
    const [maxFW] = useState(8);
    const [maxDef] = useState(2);
    const [maxGoa] = useState(1);
    const [maxUtil] = useState(1);
    const [maxBen] = useState(3);
    const [userRoster, setUserRoster] = useState(username);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getAllUsers().then(users => {
            setUsers(users);
        });

        socket.on("setUserRoster", rosterRet => {
            setRosterSlots().then(arrRes => {
                setRosterPlayers(rosterRet, arrRes);
            });
        });
    }, []);

    const setRosterPlayers = (rosterRet, arrRes) => {
        let countFW = 0;
        let countDef = 0;
        let countGoa = 0;
        let countUtil = 0;
        let countBen = 0;
        let fwdCopy = _.cloneDeep(arrRes.arrFW);
        let defCopy = _.cloneDeep(arrRes.arrDef);
        let goaCopy = _.cloneDeep(arrRes.arrGoa);
        let utilCopy = _.cloneDeep(arrRes.arrUtil);
        let benCopy = _.cloneDeep(arrRes.arrBen);

        if (rosterRet.length > 0) {
            rosterRet.forEach(val => {
                if (val.name === null) {
                    return;
                }

                let valArr = { _id: val._id, name: val.name, injury: val.injury };

                switch (val.position) {
                    case 'FWD':
                        if (countFW >= maxFW && countUtil < maxUtil) {
                            utilCopy.splice(countUtil, 1, valArr);
                            countUtil++;
                        }
                        else if(countFW >= maxFW && countUtil >= maxUtil) {
                            benCopy.splice(countBen, 1, valArr);
                            countBen++;
                        }
                        else {
                            fwdCopy.splice(countFW, 1, valArr);
                            countFW++;
                        }
                        break;
                    case 'DEF':
                        if (countDef >= maxDef && countUtil < maxUtil) {
                            utilCopy.splice(countUtil, 1, valArr);
                            countUtil++;
                        }
                        else if(countDef >= maxDef && countUtil >= maxUtil) {
                            benCopy.splice(countBen, 1, valArr);
                            countBen++;
                        }
                        else {
                            defCopy.splice(countDef, 1, valArr);
                            countDef++;
                        }
                        break;
                    case 'GOA':
                        if (countGoa >= maxGoa) {
                            benCopy.splice(countBen, 1, valArr);
                            countBen++;
                        }
                        else {
                            goaCopy.splice(countGoa, 1, valArr);
                            countGoa++;
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        setForwards(fwdCopy);
        setDefense(defCopy);
        setGoalies(goaCopy);
        setUtils(utilCopy);
        setBench(benCopy);
    }

    const pushToArray = (array, maxSlots) => {
        for (let i = 0; i < maxSlots; i++) {
            array.push({ _id: null, name: null, injury: '' });
        }
        return array;
    }

    const setRosterSlots = () => {
        let arrFW = [];
        let arrDef = [];
        let arrGoa = [];
        let arrUtil = [];
        let arrBen = [];

        //create empty slots in the roster
        arrFW = pushToArray(arrFW, maxFW);
        arrDef = pushToArray(arrDef, maxDef);
        arrGoa = pushToArray(arrGoa, maxGoa);
        arrUtil = pushToArray(arrUtil, maxUtil);
        arrBen = pushToArray(arrBen, maxBen);

        return Promise.resolve({ arrFW, arrDef, arrGoa, arrUtil, arrBen });
    }

    const renderForwards = () => {
        if (forwards.length > 0) {
            let count = 0;
            return forwards.map(p => {
                count++;
                return (
                    <tr key={!isEmpty(p._id) ? p._id : ("fwd" + count)}>
                        <td>FWD</td>
                        <td>
                            {p.name}
                            {renderIcons(p.injury)}
                        </td>
                    </tr>
                );
            });
        }
    }

    const renderDefense = () => {
        if (defense.length > 0) {
            let count = 0;
            return defense.map(p => {
                count++;
                return (
                    <tr key={!isEmpty(p._id) ? p._id : ("def" + count)}>
                        <td>DEF</td>
                        <td>
                            {p.name}
                            {renderIcons(p.injury)}
                        </td>
                    </tr>
                );
            });
        }
    }

    const renderGoalies = () => {
        if (goalies.length > 0) {
            let count = 0;
            return goalies.map(p => {
                count++;
                return (
                    <tr key={!isEmpty(p._id) ? p._id : ("goa" + count)}>
                        <td>GOA</td>
                        <td>
                            {p.name}
                            {renderIcons(p.injury)}
                        </td>
                    </tr>
                );
            });
        }
    }

    const renderUtils = () => {
        if (utils.length > 0) {
            let count = 0;
            return utils.map(p => {
                count++;
                return (
                    <tr key={!isEmpty(p._id) ? p._id : ("util" + count)}>
                        <td>UTL</td>
                        <td>
                            {p.name}
                            {renderIcons(p.injury)}
                        </td>
                    </tr>
                );
            });
        }
    }

    const renderBench = () => {
        if (bench.length > 0) {
            let count = 0;
            return bench.map(p => {
                count++;
                return (
                    <tr key={!isEmpty(p._id) ? p._id : ("ben" + count)}>
                        <td>BEN</td>
                        <td>
                            {p.name}
                            {renderIcons(p.injury)}
                        </td>
                    </tr>
                );
            });
        }
    }

    const renderIcons = (injury) => {
        if (!isEmpty(injury)) {
            return (
                <FontAwesomeIcon className="fa-lg handCursor dontdoit" icon="info-circle" title={injury} style={{ color: 'red', marginLeft: 3 }} />
            );
        }
        else {
            return null;
        }
    }

    const renderUsers = (users) => {
        return users.map(u => {
            return (
                <option className={`${u.username === '' && 'hidden'}`} key={u.username} value={u.username}>{u.username}</option>
            );
        });
    }

    const getUserRoster = (user) => {
        setUserRoster(user);
        handleUserRoster(user);
        socket.emit("getUserRoster", user);
    }

    return (
        <div className="col-12 col-lg-2">
            <p className="tac mb5">View Roster</p>
            <select id="selectedUserRoster" className="form-control mb20" name="selectedUserRoster"
                onChange={e => getUserRoster(e.target.value)} value={userRoster}>
                {renderUsers(users)}
            </select>

            <table id="rosterTable" className="table table-responsive table-bordered mb20">
                <thead style={{ borderBottom: '1px solid #c6c6c6' }}>
                    <tr>
                        <th className="posCol">Pos</th>
                        <th>Player</th>
                    </tr>
                </thead>
                <tbody>
                    {renderForwards()}
                    {renderDefense()}
                    {renderUtils()}
                    {renderGoalies()}
                    {renderBench()}
                </tbody>
            </table>
        </div>
    );
}

export default DraftRightPane;