import { getPlayerStats } from 'api/api_players';
import { useRef, useEffect } from 'react';

const _getPlayerStats = async () => {
    let players = [];

    await getPlayerStats().then(res => {
        players = res;
    });
    return Promise.resolve(players);
};
export { _getPlayerStats as getPlayerStats };

export async function handleSetTab(stateData, state) {
    //if they click on the same tab, don't make api call
    if (state.currentTab !== stateData.currentTab) {
        //if key is rank when switching back to players, set the key back to points
        let newStateData = {
            currentTab: stateData.currentTab,
            filters: state.filters,
            orderBy: { key: 'points', order: 'DESC' }
        }
        if (stateData.currentTab === 'watchlist') {
            newStateData = {
                currentTab: 'watchlist',
                filters: state.filters,
                orderBy: { key: 'rank', order: 'ASC' }
            }
        }
        else if (stateData.currentTab === 'players' && state.orderBy.key !== 'rank') {
            //keep the current order by
            newStateData = {
                currentTab: stateData.currentTab,
                filters: state.filters,
                orderBy: state.orderBy
            }
        }
        return Promise.resolve(newStateData);
    }
}

export function handleSearch(stateData, state) {
    const newStateData = {
        currentTab: state.currentTab,
        filters: stateData,
        orderBy: state.orderBy
    }
    return Promise.resolve(newStateData);
}

export function handleSort(stateData, currentTab, filters) {
    const newStateData = {
        currentTab: currentTab,
        filters: filters,
        orderBy: stateData
    }
    return Promise.resolve(newStateData);
}

export function useBeforeUnload(fn) {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onBeforeUnload = (...args) => {
            cb.current?.(...args);
        }

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => window.removeEventListener("beforeunload", onBeforeUnload);
    }, []);
};

export function useUnload(fn) {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => {
            cb.current?.(...args);
        }

        window.addEventListener("unload", onUnload);

        return () => window.removeEventListener("unload", onUnload);
    }, []);
};