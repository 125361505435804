/* eslint react-hooks/exhaustive-deps: 0 */
import React, {useState, useEffect} from 'react';
import { getPicks } from 'api/api_draft';
import { isEmpty } from 'services/global';

const DraftLeftPane = ({ socket, username }) => {

    const [currentPick, setCurrentPick] = useState(null);
    const [picks, setPicks] = useState([]);
    const [draftPicks, setDraftPicks] = useState([]);

    //only load the picks once, so return an empty array
    useEffect(() => {
        socket.emit("getCurrentPickAndDrafter");
        socket.emit("getDraftPicks");
        getPicks().then(picksArr => {
            setPicks(picksArr['picks']);
        });
    }, []);

    useEffect(() => {
        socket.on("setCurrentPickAndDrafter", currentPick => {
            setCurrentPick(currentPick);
        });
        socket.on("setDraftPicks", draftPicks => {
            setDraftPicks(draftPicks);
        });
    }, [currentPick, draftPicks]);

    const tableDivRef = React.createRef();
    //auto scroll the picks div once the round is done
    if ((currentPick % 8) === 1 && currentPick !== 1 && !isEmpty(tableDivRef.current)) {
        //37.6 is the height of one of the rows (most of the time)
        tableDivRef.current.scrollTop = (currentPick * 37.6);
    }

    const renderPicks = () => {
        let count = 0;
        return picks.map(p => {
            count++;
            return (
                <React.Fragment key={`${p._id}`}>
                    <tr id={`pick${p.pick}`}>
                        <td className={`pickNumCol ${p.fkUsername === username ? 'myPicks' : ''}`}>
                            {p.pick}
                        </td>
                        {draftPicks[count -1] === undefined || draftPicks[count -1] === null || draftPicks[count -1] === '' ?
                            <td className={`${p.fkUsername === username ? 'myPicks' : ''}`}>
                                {p.fkUsername} {!isEmpty(p.via) ? ('(via ' + p.via + ')') : ''}
                            </td>
                            :
                            <td className={`${p.fkUsername === username ? 'myPicks' : ''}`}>
                                {p.fkUsername} - <b>{draftPicks[count -1].name}</b>
                            </td>
                        }
                    </tr>
                    { !(count % 8) && count !== picks.length &&
                        <tr id={`round${(count / 8) + 1}`}>
                            <td colSpan="2" className="tac" style={{ backgroundColor: '#0f2e3a' }}>
                                <b>Round {(count / 8) + 1}</b>
                            </td>
                        </tr>
                    }
                </React.Fragment>
            );
        });
    }

    return (
        <div className="col-12 col-lg-3 col-xl-2 mb20">
            <div id="roundsDiv" ref={tableDivRef}>
                <table id="picksTable" className="table table-responsive table-bordered">
                    <tbody>
                        <tr key="rowRound1" id="round1">
                            <td colSpan="2" className="tac" style={{ backgroundColor: '#0f2e3a' }}><b>Round 1</b></td>
                        </tr>
                        {renderPicks()}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DraftLeftPane;