import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getDraftPlans, updateDraftPlans } from 'api/api_draftplans';

class DraftPlans extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            positions: [
                { label: '', value: null },
                { label: 'Forward', value: 'FWD' },
                { label: 'Defense', value: 'DEF' },
                { label: 'Goalie', value: 'GOA' },
            ],
            draftPlans: this.props.draftPlans,
            keepers: this.props.keepers,
            maxFW: 8,
            maxDEF: 6,
            maxGOA: 3,
            didUpdatePlans: false,
            isSaving: false
        }
    }

    componentDidMount() {
        document.title = "My Draft Plans";
        this.props.dispatch(getDraftPlans()).then(res => {
            this.setState({
                draftPlans: res.draftPlans,
                keepers: res.keepers,
                maxFW: (11 - parseInt(res.keepers.keeperFW)),
                maxDEF: (6 - parseInt(res.keepers.keeperDef))
            });
        });
    }

    componentWillUnmount() {
        this.props.dispatch({ type: 'UNMOUNT_DRAFT_PLANS' });
    }

    saveDraftPlans = () => {
        this.setState({isSaving: true});

        updateDraftPlans(this.state.draftPlans).then((res) => {
            this.setState({isSaving: false});

            if (res > 0) {
                return toast.success('Successfully updated ' + res + ' table ' + (res === 1 ? 'row' : 'rows'));
            }
            return toast.error('Failed to update your draft plans. This could be because no changes were made.');
        })
    }

    async changePosition(event) {
        this.setState({didUpdatePlans: true});
        const object = this.state.draftPlans;
        const index = Object.keys(object).find(key => object[key]['_id'] === event.target.id);

        await this.setState(async (prevState) => {
            //create a copy of the state
            let draftPlans = [...prevState.draftPlans];
            //update the position for the index in the array
            draftPlans[index].planPosition = event.target.value;

            let fwdCount = 0;
            let defCount = 0;
            let goaCount = 0;

            await draftPlans.map(p => {
                switch (p.planPosition) {
                    case 'FWD':
                        fwdCount++;
                        if(fwdCount > this.state.maxFW){
                            toast.warning("NOTE: You can only draft a max of " + this.state.maxFW + " forwards! " + 
                            "Please change your draft plan to have more defense or goalies.");
                        }
                    break;
                    case 'DEF':
                        defCount++;
                        if(defCount > this.state.maxDEF){
                            toast.warning("NOTE: You can only draft a max of " + this.state.maxDEF + " defensemen! " + 
                            "Please change your draft plan to have more forwards or goalies.");
                        }
                    break;
                    case 'GOA':
                        goaCount++;
                        if(goaCount > this.state.maxGOA){
                            toast.warning("NOTE: You can only draft a max of 3 goalies! " + 
                            "Please change your draft plan to have more forwards or defensemen.");
                        }
                    break;
                    default:
                        break;
                }
                return true;
            });
            //returning this sets the state to what we changed
            return { draftPlans };
        });
    }

    renderPlans = () => {
        const { draftPlans } = this.props;
        return draftPlans.map(p =>
            <tr key={p._id}>
                <td>{p.pick}</td>
                <td>
                    <select id={p._id} className="form-control" style={{ width: '200px' }}
                        defaultValue={p.planPosition} onChange={this.changePosition.bind(this)}>
                        {
                            this.state.positions.map(option =>
                                <option key={option.value} value={option.value} className={option.value === '' ? 'hidden' : ''}>
                                    {option.label}
                                </option>
                            )
                        }
                    </select>
                </td>
            </tr>
        )
    }

    render() {
        return (
            <>
                <h2 className="mb20">My Draft Plans</h2>
                <button type="button" className="btn btn-main mb20" onClick={this.saveDraftPlans} disabled={!this.state.didUpdatePlans}>
                    Save Changes
                </button>
                <table className="table table-responsive table-bordered mb20" style={{ background: 'white', color: 'black' }}>
                    <thead style={{ borderBottom: '1px solid #c6c6c6' }}>
                        <tr>
                            <th>Pick</th>
                            <th>Position To Draft</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderPlans()}
                    </tbody>
                </table>

                <button type="button" className="btn btn-main" onClick={this.saveDraftPlans} disabled={!this.state.didUpdatePlans || this.state.isSaving === true}>
                    Save Changes
                </button>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        draftPlans: state.draftPlans,
        keepers: state.keepers
    }
}

export default connect(mapStateToProps)(DraftPlans);