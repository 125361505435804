import React from 'react';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

const Error = ({ children }) =>
    <div className="alert alert-danger" style={{ width: 325 }}>
        {children}
    </div>

const LoginForm = ({ onSubmit }) => {

    const { register, handleSubmit, errors } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                    ref={register({
                        required: "Username is required."
                    })}
                    name="username"
                    type="text"
                    className="form-control"
                    id="username"
                    style={{ width: 325 }} />
                <ErrorMessage as={<Error />} errors={errors} name="username">
                    {({ message }) => <p>{message}</p>}
                </ErrorMessage>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                    ref={register({
                        required: "Password is required"
                    })
                    }
                    name="password"
                    type="password"
                    className="form-control"
                    id="password"
                    style={{ width: 325 }} />
                <ErrorMessage as={<Error />} errors={errors} name="password">
                    {({ message }) => <p>{message}</p>}
                </ErrorMessage>
            </div>
            <button
                type="submit"
                className="btn btn-main form-group">
                Submit
            </button>
        </form>
    )
}

export default LoginForm;