import {connect} from 'react-redux';

const DraftCenterPane = ({children}) => {

    return (
        <div className="col-12 col-lg-7 col-xl-8 mb20">
            {children}
        </div>
    );
}

export default connect()(DraftCenterPane);