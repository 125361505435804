/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';

//other components
import { DraftHeader } from 'components/draft/draft_header';
import DraftTable from 'components/draft/draft_table_data';
import DraftTabs from 'components/draft/draft_tabs';

//panes
import DraftLeftPane from 'components/draft/panes/draft_left_pane';
import DraftCenterPane from 'components/draft/panes/draft_center_pane';
import DraftRightPane from 'components/draft/panes/draft_right_pane';
import DraftChat from 'components/draft/draft_chat';

//shared components
import TableHeaders from 'components/shared/table_headers';
import Search from 'components/shared/search';
import { useBeforeUnload, useUnload } from 'components/shared/functions';

//authentication
import { useAuth } from 'providers/AuthProvider';

import io from "socket.io-client";
import { isEmpty } from 'services/global';
import SweetAlert from 'react-bootstrap-sweetalert';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

//since sockets have trouble connecting right away with no previous connection,
//we need to instantiate this socket outside of the Draft call so that it can linger
//and allow for socket calls. We will replace the username with the proper username
//immediately once the draft page is opened.
var socket = io(ENDPOINT, {
    closeOnBeforeunload: false,
    query: {
        "room": "ykl",
        "username": 'x_placeholder_x'
    } 
});

const Draft = () => {
    const authService = useAuth();
    const username = authService.getUsername();

    //If the username is placeholder, disconnect that socket and connect with this new one with the proper username!
    //Tried just simply updating the socket, but no bueno :/
    if(!isEmpty(socket)){
        if(isEmpty(socket.io.engine.opts.query.username) || socket.io.engine.opts.query.username === 'x_placeholder_x') {
            socket.disconnect();
            socket = io(ENDPOINT, {
                closeOnBeforeunload: false,
                query: {
                    "room": "ykl",
                    "username": username,
                } 
            });
        }
    }
    else if(isEmpty(socket)) {
        socket = io(ENDPOINT, {
            closeOnBeforeunload: false,
            query: {
                "room": "ykl",
                "username": username,
            } 
        });
    }

    //this page (index)
    const [showConfirmAlert, setShowConfirmAlert] = useState(false);
    //search
    const [filters, setFilters] = useState({});
    //center pane - tabs & text
    const [currentTab, setCurrentTab] = useState('players');
    //center pane - table
    const [playerObjDrafted, setPlayerObjDrafted] = useState(null);
    const [playerSelected, setPlayerSelected] = useState(null);
    const [orderBy, setOrderBy] = useState({ key: 'points', order: 'DESC' });
    //right pane - roster
    const [userRoster, setUserRoster] = useState(username);

    useBeforeUnload(e => {
        e.preventDefault();
        e.returnValue = '';
    });

    useUnload(() => {
        document.documentElement.classList.remove("draftClass");

        if(!isEmpty(socket)){
            socket.disconnect();
            //when we unmount, set socket to null after the disconnect,
            //so it can reconnect properly
            socket = null;
        }
    });

    useEffect(() => {
        document.documentElement.classList.add("draftClass");
        document.title = "LIVE DRAFT!";

        return () => {
            document.documentElement.classList.remove("draftClass");
            if(!isEmpty(socket)){
                socket.disconnect();
                //when we unmount, set socket to null after the disconnect,
                //so it can reconnect properly
                socket = null;
            }
        }
    }, []);

    const handleSetTab = currentTab => {
        setCurrentTab(currentTab);

        if (currentTab === 'watchlist') {
            setOrderBy({ key: 'rank', order: 'ASC' });
        }
        else if (currentTab === 'players' && orderBy.key === 'rank') {
            setOrderBy({ key: 'points', order: 'DESC' });
        }
    }

    const handleSearch = filters => {
        setFilters(filters);
    }

    const handleSortFunc = orderBy => {
        setOrderBy(orderBy);
    }

    const handleUserRoster = userRoster => {
        setUserRoster(userRoster);
    }

    const handleShowConfirm = playerSelected => {
        setShowConfirmAlert(true);
        setPlayerSelected(playerSelected);
    }

    const handleSetPlayerObjDrafted = () => {
        setPlayerObjDrafted(null);
    }

    const draftSkater = () => {
        socket.emit('setIsSelectingPlayer', true);
        setPlayerObjDrafted(playerSelected);
        setShowConfirmAlert(false);
    }

    const hideAlert = () => {
        setShowConfirmAlert(false);
    }

    return (
        <>
        <SweetAlert
                null
                show={showConfirmAlert}
                showCancel
                allowEscape={false}
                closeOnClickOutside={false}
                cancelBtnText="No"
                cancelBtnBsStyle="light"
                confirmBtnText="Yes"
                confirmBtnBsStyle="success"
                title={isEmpty(playerSelected) ? '' : 
                    `Draft ${playerSelected.position} - ${playerSelected.name} (${playerSelected.team})?`}
                onConfirm={draftSkater}
                onCancel={hideAlert}
                style={{ color: 'black', height: '250px' }}
            >
            </SweetAlert>
            <DraftHeader socket={socket} username={username} showConfirmAlert={showConfirmAlert} />
            <Search handleSearch={handleSearch} />
            <div className="row">
                <DraftLeftPane socket={socket} username={username} />
                <DraftCenterPane>
                    <DraftTabs socket={socket} username={username} handleSetTab={handleSetTab} />
                    <div id="draftTableDiv">
                        <table id="draftPlayersTable" className="table table-responsive table-bordered table-striped">
                            <TableHeaders handleSortFunc={handleSortFunc} currentTab={currentTab} orderBy={orderBy} />
                            <DraftTable socket={socket} currentTab={currentTab} handleShowConfirm={handleShowConfirm} username={username}
                                filters={filters} orderBy={orderBy} userRoster={userRoster} playerObjDrafted={playerObjDrafted}
                                handleSetPlayerObjDrafted={handleSetPlayerObjDrafted} />
                        </table>
                    </div>
                </DraftCenterPane>
                <DraftRightPane socket={socket} username={username} handleUserRoster={handleUserRoster}/>
            </div>
            <DraftChat socket={socket} username={username} />
        </>
    )
}

export default Draft;