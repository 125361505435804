import {combineReducers} from 'redux';

const initAuthReducer = () => {
    const players = (state = [], action) => {

        switch (action.type) {
            case 'UNMOUNT_PLAYERS':
                return [];
            case 'GET_PLAYERS':
                return action.players;
            default:
                return state;
        }
    }
    
    const teams = (state = [], action) => {
        switch (action.type) {
            case 'UNMOUNT_TEAMS':
                return [];
            case 'GET_TEAMS':
                return action.teams;
            default:
                return state;
        }
    }

    return combineReducers({
        players,
        teams
    });
}

const players = initAuthReducer();

export default players;