import YklService from 'services/YklService';

const {yklAxios} = YklService;

export const getTeams = () => dispatch => {
    return yklAxios.get('/teams').then(res => {
        return dispatch({
            type: 'GET_TEAMS',
            teams: res.data
        });
    });
}