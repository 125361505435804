import YklService from 'services/YklService';

const {yklAxios} = YklService;

export const getDraftYears = () => dispatch => {
    return yklAxios.get('/draftyears').then(res => {
        return dispatch({
            type: 'GET_DRAFT_YEARS',
            draftYears: res.data
        });
    });
}