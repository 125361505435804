import React from 'react';

class PlayersTabs extends React.Component {
    state = {
        currentTab: 'players'
    }

    setTab = (e) => {
        if (this.state.currentTab !== e.target.value) {
            this.setState({ currentTab: e.target.value }, () => {
                this.props.handleSetTab(this.state.currentTab);
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 col-lg-6 mb10">
                    <button type="button" className={`handCursor btn btn-block ${this.state.currentTab === 'players' ? 'btn-main' : 'btn-outline-main'}`}
                        onClick={this.setTab} value="players">
                        Players
                    </button>
                </div>
                <div className="col-12 col-lg-6 mb10">
                    <button type="button" className={`handCursor btn btn-block ${this.state.currentTab !== 'players' ? 'btn-main' : 'btn-outline-main'}`}
                        onClick={this.setTab} value="watchlist">
                        My Watchlist
                    </button>
                </div>
            </div>
        )
    }
}

export default PlayersTabs;