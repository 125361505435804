import React from 'react';
import { Redirect } from 'react-router-dom';

import LoginForm from 'components/forms/LoginForm';
import ApiErrors from 'components/forms/ApiErrors';
import { withAuth } from 'providers/AuthProvider';

class Login extends React.Component {

    componentDidMount() {
        document.title = "YKL Login";
    }

    state = {
        shouldRedirect: false,
        errors: []
    }

    signIn = (loginData) => {
        this.props.auth.signIn(loginData).then(() => {
            this.setState({ shouldRedirect: true });
        }).catch(errors => {
            this.setState({ errors });
        });
    }

    render() {
        const { errors, shouldRedirect } = this.state;

        //TODO: for now, redirect back to home, but fix to go to players
        if (shouldRedirect) {
            return <Redirect to={{ pathname: '/home' }} />
        }

        return (
            <>
                <LoginForm onSubmit={this.signIn} />
                <ApiErrors errors={errors} />
            </>
        )
    }
}

export default withAuth(Login);