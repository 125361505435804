import React from 'react';

const ApiErrors = ({errors}) => {

    if(errors && errors.length > 0){
        return (
            <div className="alert alert-danger" style={{width: 325}}>
                { errors.map(e => <span key={e.title}>{e.detail}</span>) }
            </div>
        )
    }
    else{
        return null;
    }
}

export default ApiErrors;